import React, { useState, useEffect } from "react";
import "./Categories.css";
import axiosConfig from "../../Service/axiosConfig";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
const Categories = () => {
  const [loading, setLoading] = useState(false);
  const [Data, SetData] = useState([]);
  const GetData = async () => {
    setLoading(true);
    try {
      const responce = await axiosConfig.get(
        "/catlog/categories/?page_size=30&is_suspended=false"
      );
      console.log(responce);
      SetData(responce.data.results);
      localStorage.setItem("categories", JSON.stringify(responce.data.results));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 766,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  useEffect(() => {
    GetData();
  }, []);

  return (
    <>
      <div className="categories-container">
        {loading ? (
          <div className="loader-container">
            <img src={require("../images/loader.gif")} alt="Loading..." />
          </div>
        ) : (
          <div className="container">
            <h2 className="cat-section-title">Shop by category</h2>
            <div className="cat-container">
              {Data.slice(0, 5).map((itemObj, index) => {
                return (
                  <div className="cat-item" key={index}>
                    <NavLink to={itemObj.subcategory_slug}>
                      <div className="cat-img">
                        <img
                          src={
                            itemObj.image ? itemObj.image.image : itemObj.image
                          }
                          className="mx-auto image-fluid"
                        />
                      </div>
                      <div className="cat-title">
                        <p>{itemObj.name}</p>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <hr className="cat-hr" />
      </div>
    </>
  );
};
export { Categories };
