import React, { useEffect, useState } from "react";
import "./Footer.css";
import axiosConfig from "../../Service/axiosConfig";
import { useNavigate, NavLink } from "react-router-dom";
import { LiaFacebook } from "react-icons/lia";
import { RxLinkedinLogo } from "react-icons/rx";
import { FiInstagram } from "react-icons/fi";
import { BsTwitterX } from "react-icons/bs";
const Footer = ({ bgColor }) => {
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [Data, SetData] = useState([]);
  const [Email, Setemail] = useState({});
  const [show, setShow] = useState(false);
  const [Error, setError] = useState("");
  const [siteData, setSiteData] = useState({});
  const [policy, setPolicy] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [brands, setBrands] = useState([]);

  const GetData = async () => {
    setLoading(true);
    try {
      const responce = await axiosConfig.get(
        "/catlog/categories/?page_size=30&is_suspended=false"
      );
      SetData(responce.data.results);
      localStorage.setItem("categories", JSON.stringify(responce.data.results));
      setSiteData(JSON.parse(localStorage.getItem("site_data")));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const policies = async () => {
    setLoading(true);
    try {
      const responce = await axiosConfig.get("/catlog/policies/");
      localStorage.setItem(
        "policies",
        JSON.stringify(responce.data.results[0])
      );
      const blog_response = await axiosConfig.get(`/blogapp/blog/`);
      setBlogs(blog_response.data.results);
      setPolicy(responce.data.results[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const GetBrands = async () => {
    try {
      const response = await axiosConfig.get(`catlog/brands/?page_size=1000`);
      setBrands(response.data.results);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    policies();
    GetBrands();
  }, []);
  const divideIntoColumns = (data, numColumns) => {
    const dividedData = [];
    const columnSize = Math.ceil(data.length / numColumns);

    for (let i = 0; i < data.length; i += columnSize) {
      dividedData.push(data.slice(i, i + columnSize));
    }

    return dividedData;
  };

  const renderColumns = (data) => {
    return data.map((column, index) => {
      return (
        <div className="col-md-4 col-6">
          <ul className="list-unstyled">
            {column.map((item, itemIndex) => {
              return (
                <li key={itemIndex}>
                  <NavLink to={`/${item.slug}`}>{item.name}</NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  };
  const HandleSubscribe = (e) => {
    const { name, value } = e.target;
    Setemail({ ...Email, [name]: value });
  };
  const GetEmail = async () => {
    try {
      const response = await axiosConfig.post(`/masters/subscribe/`, Email);
      console.log(response);
      setError("Success: Your e-mail has been subscribed successfully!");
    } catch (error) {
      setShow(true);
      console.log(error.response.data.email[0]);
      if (error.response.data.email[0] === "Enter a valid email address.") {
        setError("Invalid Email Address!");
      }
      if (
        error.response.data.email[0] ===
        "subscribe with this email already exists."
      ) {
        setError("Email already exists!");
      }
    }
  };

  const dividedColumns = divideIntoColumns(Data, 4);
  const GetStores = async () => {
    if (stores.length > 0) return;
    try {
      const response = await axiosConfig.get(
        `/stores/stores/?page=1&page_size=20`
      );
      setStores(response.data.results);
    } catch (error) {
      console.log("Error getting stores", error);
    }
  };
  useEffect(() => {
    GetData();
    GetStores();
  }, []);
  const [visibleCount, setVisibleCount] = useState(6);
  const hasMoreLocations = stores.length > visibleCount;

  const handleSeeMoreClick = () => {
    setVisibleCount((prevCount) => prevCount + 6);
  };
  return (
    <>
      <footer className="footer">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <h3 className="footer-h3">Useful Link</h3>
                <ul className="footer-list">
                  <a href="/about" style={{ textDecoration: "none" }}>
                    <li>About Us</li>
                  </a>
                  <li>Contact Us</li>
                  <li>FAQ</li>
                </ul>
              </div>
              <div className="col-md-3">
                <h3 className="footer-h3">Categories</h3>
                <ul className="footer-list">
                  <li>Half Sarees</li>
                  <li>DailyWearSarees</li>
                  <li>PartyWearSarees</li>
                  <li>Desiner sarees</li>
                </ul>
              </div>
              <div className="col-md-3">
                <h3 className="footer-h3">Follow us</h3>
                <div className="d-flex  gap-10">
                  <div className="d-flex  gap-10">
                    <LiaFacebook size={30} color={"white"} />
                    <RxLinkedinLogo size={30} color={"white"} />
                    <FiInstagram size={30} color={"white"} />
                    <BsTwitterX size={30} color={"white"} />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-row gap-40">
                  <p className="footer-p f-15">Terms of Service</p>
                  <p className="footer-p f-15">Privacy Policy</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="footer-hr" />
          <div className="anchor-div">
            <h4>Copyright© All rights Reserved by Anshutuffy.in</h4>
          </div>
        </section>
      </footer>
    </>
  );
};
export { Footer };
